import React from "react";

const AcceptedItem = ({ viewerEmail, details, showComma }) => {
  return (
    <div className="meeting-det-confirmed-item">
      {/*<img src={details.picture} alt="" />*/}
      {viewerEmail === details?.email ? "me" : (details?.name || details?.email)}{showComma && <>,&nbsp;</>}
    </div>
  )
};

export default AcceptedItem;
