import React, {useCallback, useEffect, useState} from "react";
import _ from "lodash";
import qs from 'qs';
import { format } from "date-fns";

import IconGoogleCalendar from "../../assets/icon_google_calendar.png";
import Sidebar from "../../components/sidebar";
import axios from "axios";
import { BACKEND_HOST, ROUTES } from "../../utils/constants";
import { Link, useParams } from "react-router-dom";
import {useAuth} from "../../utils/auth";
import AcceptedItem from "./accepted_item";
import InviteeItem, {INVITEE_STATES} from "./invitee_item";

import IconCalEdit from "../../assets/icon_cal_edit.svg";
import IconCalEditHighlight from "../../assets/icon_cal_edit_highlight.svg";
import IconCalGoogle from "../../assets/icon_cal_google.svg";
import IconCalGoogleHighlight from "../../assets/icon_cal_google_highlight.svg";
import IconLabelCheck from "../../assets/icon_label_check.svg";
import IconLabelCheckHightligt from "../../assets/icon_label_check_highlight.svg";
import IconTime from "../../assets/icon_time.svg";
import IconTimeHighlight from "../../assets/icon_time_highlight.svg";
import IconBell from "../../assets/icon_bell.svg";
import IconUserGroup from "../../assets/icon_user_group.svg";

const formatTime = timeEpoch => {
  const timeInst = new Date(+timeEpoch);
  return `${format(timeInst, "MMMM do(EEEE)")} at ${format(timeInst, "h:mmaaa")}`;
};

const MEETING_STATUSES = {
  INITIATED: "INITIATED",
  AWAITING_ACCESS: "AWAITING_ACCESS",
  TIMINGS: "TIMINGS",
  ON_CALENDAR: "ON_CALENDAR",
};

const DashboardMeetingDetails = () => {
  const { userDetails: { token, userId, email }} = useAuth();
  let { meeting_id } = useParams();
  const [details, setDetails] = useState(null);

  const acceptTiming = useCallback(async (timeId, accepted) => {
    const { data: { data: response }} = await axios({
      method: "post",
      url: `${BACKEND_HOST}/meetings/${meeting_id}/accept_timing`,
      withCredentials: true,
      data: {
        timeId,
        accepted,
      },
      headers: {
        Authorization: `Bearer ${token}`
      },
    });

    setDetails(response);
  }, [meeting_id, token]);

  useEffect(() => {
    if(details) {
      return;
    }
    const getMeetingDetails = async () => {
      const { data: { data: response }} = await axios({
        method: "get",
        url: `${BACKEND_HOST}/meetings/${meeting_id}`,
        headers: {
          Authorization: `Bearer ${token}`
        },
      });
      setDetails(response);

      const { search } = window.location;
      if(search) {
        const params = qs.parse(search.replace("?", ""));
        if(params.t) {
          acceptTiming(params.t, true);
          window.history.replaceState(null, '', window.location.pathname);
        }
      }
    }

    getMeetingDetails();
  }, [meeting_id, acceptTiming, token, details]);

  console.log(details);
  const { meetingDetails, creatorDetails, userDetails } = details || {};

  const inexistentUsers = _.filter(meetingDetails?.invitees, i => !_.some(userDetails, { email: i }));

  let meetingStatus = MEETING_STATUSES.INITIATED;
  if(_.size(inexistentUsers) > 0) {
    meetingStatus = MEETING_STATUSES.AWAITING_ACCESS;
  } else if(!!meetingDetails?.calendar_event_link) {
    meetingStatus = MEETING_STATUSES.ON_CALENDAR;
  } else {
    meetingStatus = MEETING_STATUSES.TIMINGS;
  }

  const hasUserSelectedtimings = _.some(_.compact(meetingDetails?.timings), t => _.includes(t.accepted_by, userId));

  return (
    <div id="dashboard-layout">
      <Sidebar />
      <div id="dashboard-contents">
        {
          details && (
            <>
              <Link to={ROUTES.MEETINGS} className="page-back">
                <span className="material-symbols-outlined">
                  arrow_back
                </span>
                Back to all meetings</Link>
              <h2>{meetingDetails.title}</h2>
              <div id="md-timeline">
                <div className="md-timeline-line"/>
                <div className={`md-timeline-line highlight ${meetingStatus}`} />
                <div className={`md-timeline-item past`}>
                  <div className="md-timeline-dot"/>
                  <img className="md-timeline-icon" src={IconCalEdit} alt=""/>
                  <img className="md-timeline-icon-highlight" src={IconCalEditHighlight} alt=""/>
                  <p>Meeting initiated
                    by {creatorDetails.email === email ? "me" : `${creatorDetails.name}(${creatorDetails.email})`}</p>
                </div>
                <div className={`md-timeline-item ${(() => {
                  if (meetingStatus === MEETING_STATUSES.TIMINGS || meetingStatus === MEETING_STATUSES.ON_CALENDAR) {
                    return "past";
                  }
                  return "present";
                })()}`}>
                  <div className="md-timeline-dot"/>
                  <img className="md-timeline-icon" src={IconTime} alt=""/>
                  <img className="md-timeline-icon-highlight" src={IconTimeHighlight} alt=""/>
                  <p>Awaiting invitees’ calendar access</p>
                </div>
                <div className={`md-timeline-item ${(() => {
                  if (meetingStatus === MEETING_STATUSES.ON_CALENDAR) {
                    return "past";
                  } else if (meetingStatus === MEETING_STATUSES.TIMINGS) {
                    return "present";
                  }
                  return "future";
                })()}`}>
                  <div className="md-timeline-dot"/>
                  <img className="md-timeline-icon" src={IconLabelCheck} alt=""/>
                  <img className="md-timeline-icon-highlight" src={IconLabelCheckHightligt} alt=""/>
                  <p>Confirm all attendees availability</p>
                </div>
                <div className={`md-timeline-item ${(() => {
                  if (meetingStatus === MEETING_STATUSES.ON_CALENDAR) {
                    return "present";
                  }
                  return "future";
                })()}`}>
                  <div className="md-timeline-dot"/>
                  <img className="md-timeline-icon" src={IconCalGoogle} alt=""/>
                  <img className="md-timeline-icon-highlight" src={IconCalGoogleHighlight} alt=""/>
                  <p>Schedule on Google Calendar</p>
                </div>
              </div>
              {
                meetingStatus === MEETING_STATUSES.ON_CALENDAR && (
                  <>
                    <div className="meeting-det-cal">
                      <img className="meeting-det-cal-icon" src={IconGoogleCalendar} alt=""/>
                      <div className="meeting-det-cal-contents">
                        <h4>Meeting confirmed for {formatTime(meetingDetails.confirmed_time.start_time)}</h4>
                        <p>Respond to the Google calendar invite created to let other attendees know:</p>
                        <p>
                          <a className="btn short primary" href={meetingDetails.calendar_event_link} target="_blank"
                             rel="noreferrer">
                            Respond on Google Calendar
                          </a>
                        </p>
                      </div>
                    </div>
                    <h4>Attendees</h4>
                    <p className="attendees-list">
                      {
                        userDetails.map(u => (
                          <React.Fragment key={u.id}>{u.name}({u.email})<br /></React.Fragment>
                        ))
                      }
                    </p>
                  </>
                )
              }
              {
                meetingStatus === MEETING_STATUSES.TIMINGS && (
                  <>
                    {
                      hasUserSelectedtimings ? (
                        <div className="meeting-det-cal">
                          <img className="meeting-det-cal-icon" src={IconUserGroup} alt=""/>
                          <div className="meeting-det-cal-contents">
                            <h4>Waiting for other attendees' preference</h4>
                            <p>Meetng will schedule an event on Google Calendar and let you know once all the attendees have confirmed their availability</p>
                          </div>
                        </div>
                      ) : (
                        <div className="meeting-det-cal">
                          <img className="meeting-det-cal-icon" src={IconBell} alt=""/>
                          <div className="meeting-det-cal-contents">
                            <h4>Choose your convenient time below</h4>
                            <p>Meetng AI’s algorithm has come up with the best available timings based on calendar
                              availability of the invitees.</p>
                          </div>
                        </div>
                      )
                    }
                    <div className="flex">
                      <div className="meeting-det-timings">
                        <h4>Timings</h4>
                        {
                          _.map(_.sortBy(meetingDetails.timings, "start_time"), (t, index) => {
                            if (!t) {
                              return null;
                            }
                            const isTimeAccepted = _.includes(t.accepted_by, userId);
                            const awaitingUsers = _.compact(
                              _.map([
                                ...(meetingDetails?.invitees || []),
                                creatorDetails.email,
                              ], i => {
                                let status;
                                const invDetails = _.find(userDetails, {email: i});
                                if (!invDetails) {
                                  status = INVITEE_STATES.INEXISTENT
                                } else if (_.includes(t.accepted_by, invDetails.id)) {
                                  status = INVITEE_STATES.CONFIRMED;
                                } else {
                                  status = INVITEE_STATES.UNCONFIRMED;
                                }

                                if (status === INVITEE_STATES.CONFIRMED) {
                                  return null;
                                }

                                return {
                                  email: i,
                                  name: invDetails?.name,
                                  status,
                                };
                              })
                            );
                            return (
                              <div key={t.start_time} className="meeting-det-time-item">
                                <div className={`radio-button-icon ${
                                  isTimeAccepted ? "selected" : ""
                                }`} onClick={() => acceptTiming(t.id, !isTimeAccepted)}>
                                  <span className="material-symbols-outlined">check_small</span>
                                </div>
                                <div>
                                  {formatTime(t.start_time)}
                                  <div className="meeting-det-confirmed">
                                    Confirmed
                                    by:&nbsp;&nbsp;{_.size(t.accepted_by) > 0 ? _.map(_.compact(t.accepted_by), (a, aIndex) => (
                                    <AcceptedItem
                                      key={a}
                                      viewerEmail={email}
                                      details={_.find(userDetails, {id: a})}
                                      showComma={aIndex !== t.accepted_by.length - 1}
                                    />
                                  )) : "N/A"}
                                  </div>
                                  <div className="meeting-det-confirmed">
                                    Awaiting:&nbsp;&nbsp;{_.size(awaitingUsers) > 0 ? _.map(awaitingUsers, (a, aIndex) => (
                                    <InviteeItem viewerEmail={email} key={a.email} details={a}/>
                                  )) : "N/A"}
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        }
                      </div>
                    </div>
                  </>
                )
              }
              {
                meetingStatus === MEETING_STATUSES.AWAITING_ACCESS && (
                  <div className="flex">
                    <div className="progress-blink" />
                    <div>
                      <h4>Meetng AI is awaiting {
                        _.size(inexistentUsers) > 1 ? `${_.size(inexistentUsers)} invitees’` : "an invitee's"
                      } calendar access</h4>
                      <p className="det-inexistent">
                        {inexistentUsers[0]} {
                        _.size(inexistentUsers) > 1 ? `and ${_.size(inexistentUsers) - 1} others do not` : "does not"
                      } have an account on Meetng.<br/>
                        We will notify you once they create an account to proceed further with the meeting scheduling.
                      </p>
                    </div>
                  </div>
                )
              }
            </>
          )
        }
      </div>
    </div>
  )
};

export default DashboardMeetingDetails;
