import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';

import Landing from "./landing";
import LoginScreen from "./login";
import { GOOGLE_OAUTH_CLIENT_ID, ROUTES} from "./utils/constants";
import DashboardAccount from "./dashboard/account";
import DashboardMeetings from "./dashboard/meetings";
import DashboardTrash from "./dashboard/trash";
import DashboardApps from "./dashboard/apps";
import {AuthProvider, RequireAuth} from "./utils/auth";
import DashboardMeetingDetails from "./dashboard/meeting_details";
import PrivacyPolicy from "./other-pages/privacy_policy";
import Support from "./other-pages/support";
import ExtensionSuccess from "./extension-success";

const App = () => {
  return (
    <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route
              exact
              path={ROUTES.ROOT}
              element={<Landing />}
            />
            <Route
              exact
              path={ROUTES.PRIVACY_POLICY}
              element={<PrivacyPolicy />}
            />
            <Route
              exact
              path={ROUTES.SUPPORT}
              element={<Support />}
            />
            <Route
              exact
              path={ROUTES.EXTENSION_SUCCESS}
              element={<ExtensionSuccess />}
            />
            <Route
              exact
              path={ROUTES.LOGIN}
              element={<LoginScreen />}
            />
            <Route
              exact
              path={ROUTES.MEETING_DETAILS}
              element={
                <RequireAuth>
                  <DashboardMeetingDetails />
                </RequireAuth>
              }
            />
            <Route
              exact
              path={ROUTES.MEETINGS}
              element={
                <RequireAuth>
                  <DashboardMeetings />
                </RequireAuth>
              }
            />
            <Route
              exact
              path={ROUTES.TRASH}
              element={
                <RequireAuth>
                  <DashboardTrash />
                </RequireAuth>
              }
            />
            <Route
              exact
              path={ROUTES.ACCOUNT}
              element={
                <RequireAuth>
                  <DashboardAccount />
                </RequireAuth>
              }
            />
            <Route
              exact
              path={ROUTES.APPS}
              element={
                <RequireAuth>
                  <DashboardApps />
                </RequireAuth>
              }
            />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
};

export default App;
