import React from "react";
import Sidebar from "../../components/sidebar";

const DashboardTrash = () => {
  return (
    <div id="dashboard-layout">
      <Sidebar />
      <div id="dashboard-contents">
        <h2>Trash</h2>
        <p>Coming soon!</p>
      </div>
    </div>
  )
};

export default DashboardTrash;
