export const ROUTES = {
  ROOT: "/",
  LOGIN: "/login",
  MEETINGS: "/meetings",
  TRASH: "/trash",
  ACCOUNT: "/account",
  APPS: "/apps",
  MEETING_DETAILS: "/meetings/:meeting_id",
  PRIVACY_POLICY: "/privacy_policy",
  SUPPORT: "/support",
  EXTENSION_SUCCESS: "/extension-success"
};

export const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST;
export const CHROME_EXTENSION_ID = process.env.REACT_APP_CHROME_EXTENSION_ID;
export const CHROME_WEBSTORE_PAGE = `https://chromewebstore.google.com/detail/${CHROME_EXTENSION_ID}`;

export const BACKEND_URLS = {
  GOOGLE_LOGIN: `${BACKEND_HOST}/auth/google_login`,
  LOGOUT: `${BACKEND_HOST}/auth/logout`,
  GET_USER_DETAILS: `${BACKEND_HOST}/auth/user_details`,
  GET_MEETINGS_LIST: `${BACKEND_HOST}/meetings/all`,
  GET_MEETING_DETAILS: `${BACKEND_HOST}/meetings/:meeting_id`,
};

export const GOOGLE_OAUTH_CLIENT_ID = "215075415990-bsime6ti65s0our0hlkf1ovpfk9q8o0k.apps.googleusercontent.com";

export const LOGIN_STATES = {
  INITIAL: "INITIAL",
  PROGRESS: "PROGRESS",
  LOGGED_IN: "LOGGED_IN",
  ERROR: "ERROR",
  LOGGED_OUT: "LOGGED_OUT",
};
