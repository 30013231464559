import React, {useEffect, useState} from "react";
import _ from "lodash";
import axios from "axios";

import Sidebar from "../../components/sidebar";
import ChromeBannerIcon from "../../assets/chrome_webstore_logo.png";
import {BACKEND_URLS, CHROME_WEBSTORE_PAGE} from "../../utils/constants";
// import {generatePath, Link} from "react-router-dom";
import {useAuth} from "../../utils/auth";
import MeetingListItem from "./meeting_list_item";

const DashboardMeetings = () => {
  const { userDetails: { token }} = useAuth();
  const [list, setList] = useState([]);

  useEffect(() => {
    const getMeetingsList = async () => {
      const { data: { data: response }} = await axios({
        method: "get",
        url: BACKEND_URLS.GET_MEETINGS_LIST,
        headers: {
          Authorization: `Bearer ${token}`
        },
      });
      setList(response);
    }

    getMeetingsList();
  }, [token]);

  return (
    <div id="dashboard-layout">
      <Sidebar />
      <div id="dashboard-contents">
        <div id="chrome-webstore-banner">
          <img src={ChromeBannerIcon} alt="" id="chrome-banner-icon" />
          <div id="chrome-banner-contents">
            <h4>Download our official Chrome Extension from the WebStore</h4>
            <p>
              Schedule your meetings right from GMail with just one click
            </p>
          </div>
          <a className="btn primary" id="chrome-banner-btn" href={CHROME_WEBSTORE_PAGE}>
            <span className="material-symbols-outlined">
            open_in_new
            </span>
            Download extension
          </a>
        </div>
        <h2>Meetings</h2>
        <div id="meeting-list">
          {
            _.size(list) === 0 && (
              <p>You have no meetings scheduled</p>
            )
          }
          {
            _.map(list, l => (
              <MeetingListItem key={l.id} details={l} />
            ))
          }
        </div>
      </div>
    </div>
  )
};

export default DashboardMeetings;
