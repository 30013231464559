import React from "react";
import _ from "lodash";
import {generatePath, Link} from "react-router-dom";

import {ROUTES} from "../../utils/constants";

const MEETING_STATUSES = {
  PENDING: "Awaiting confirmation",
  SCHEDULED: "Scheduled",
  COMPLETED: "Completed"
};

const getMeetingStatus = (scheduledMeeting) => {
  if(!scheduledMeeting) {
    return MEETING_STATUSES.PENDING;
  }
  if(scheduledMeeting.end < +(new Date())) {
    return MEETING_STATUSES.COMPLETED;
  }
  return MEETING_STATUSES.SCHEDULED;
}

const MEETING_STATUS_ICONS = {
  [MEETING_STATUSES.PENDING]: "pending_actions",
  [MEETING_STATUSES.SCHEDULED]: "schedule_send",
  [MEETING_STATUSES.COMPLETED]: "event_available",
};

const MeetingListItem = ({ details }) => {
  const { id, title, invitees, creator_name } = details;
  // console.log(details);
  const scheduledMeeting = _.find(details.timings, t => _.size(t.accepted_by) === _.size(invitees))
  const meetingStatus = getMeetingStatus(scheduledMeeting);
  return (
    <Link to={generatePath(ROUTES.MEETING_DETAILS, {meeting_id: id})} className="meeting-list-item">
      <span className="material-symbols-outlined">
        {MEETING_STATUS_ICONS[meetingStatus]}
      </span>
      <div className="meeting-item-contents">
        <p className="meeting-item-title">
          {title}
        </p>
        <p className="meeting-item-invited">
          by {creator_name}
          {/*&bull; {meetingStatus}*/}
        </p>
      </div>
    </Link>
  )
};

export default MeetingListItem;
