import React from "react";
import LogoDashboard from "../assets/logo_grey.svg";
import {Link, NavLink} from "react-router-dom";
import {ROUTES} from "../utils/constants";
import {useAuth} from "../utils/auth";

const Sidebar = () => {
  const auth = useAuth();

  return (
    <div id="sidebar">
      <div id="sidebar-logo-wrapper">
        <Link to={ROUTES.MEETINGS}>
          <img src={LogoDashboard} id="sidebar-logo" alt="Meetng Logo"/>
        </Link>
      </div>
      <div id="sidebar-nav">
        <NavLink to={ROUTES.MEETINGS} className="sidebar-item">
          <span className="material-symbols-outlined">
            event
          </span>
          Meetings
        </NavLink>
        <NavLink to={ROUTES.TRASH} className="sidebar-item">
          <span className="material-symbols-outlined">
            delete
          </span>
          Trash
        </NavLink>
        <NavLink to={ROUTES.ACCOUNT} className="sidebar-item">
          <span className="material-symbols-outlined">
            person
          </span>
          Account
        </NavLink>
        <NavLink to={ROUTES.APPS} className="sidebar-item">
          <span className="material-symbols-outlined">
            apps
          </span>
          Apps
        </NavLink>
      </div>
      <div id="sidebar-bottom">
        <button className="sidebar-item" onClick={auth.logout}>
          <span className="material-symbols-outlined">
            logout
          </span>
          <div>
            Sign out<br />
            <span id="sidebar-email">{auth.userDetails.email}</span>
          </div>
        </button>
      </div>
    </div>
  );
};

export default Sidebar;


