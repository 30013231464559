import React from "react";
import StaticPageContent from "../components/static-page-content";

const Support = () => {
  return (
    <StaticPageContent>
      <h1>Support</h1>
      <p>
        Meetng App is a product built and owned by <a href="https://www.adya.care/">Adya Health PTE Ltd.</a><br />
        For any queries and support, please reach out to us at admin@meetng.app or admin@adya.care
      </p>
    </StaticPageContent>
  )
};

export default Support;
