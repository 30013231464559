import React from "react";
import {Link} from "react-router-dom";

import IconGoogleWhite from "../assets/icon_google_white.svg";
import LandingIllustration from "../assets/landing_illustration.svg";
import {ROUTES} from "../utils/constants";
import LandingHeader from "./header";

const LandingScreen = () => {
  return (
    <div>
      <LandingHeader />
      <div id="landing-flex" className="landing-width">
        <div id="landing-content">
          <h1>
            Don’t waste time scheduling your time
          </h1>
          <p>
            Let out intelligent AI handle your end-to-end meeting schedules.<br />
            Be it a dinner date, or a product pitch -
            we take care of managing your mundane tasks so you can focus on your life.
          </p>
          <Link to={ROUTES.LOGIN} id="landing-login" className="btn primary">
            <img src={IconGoogleWhite} alt="" id="landing-google-icon" />
            Continue with Google
          </Link>
        </div>
        <img src={LandingIllustration} alt="" id="landing-illustration" />
      </div>
    </div>
  );
}

export default LandingScreen;
