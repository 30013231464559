import React, {useContext, useEffect, useState} from "react";
import {BACKEND_URLS, CHROME_EXTENSION_ID, LOGIN_STATES, ROUTES} from "./constants";
import {useGoogleLogin} from "@react-oauth/google";
import axios from "axios";
import {Navigate, useLocation} from "react-router-dom";

import MeetngLogo from "../assets/logo_color.png";

const browserInstance = window.chrome || window.browser;

const sendLoginTokenMessageToExtension = () => {
  if(browserInstance) {
    browserInstance.runtime?.sendMessage(CHROME_EXTENSION_ID, {
      type: "tm_login_token",
      token: localStorage.getItem("meetngapp-session"),
    });
  }
};

const sendLogoutMessageToExtension = () => {
  if(browserInstance) {
    browserInstance.runtime?.sendMessage(CHROME_EXTENSION_ID, {
      type: "tm_logout",
    });
  }
};

export const AuthContext = React.createContext(null);

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("meetngapp-session"));
  const [userDetails, setUserDetails] = useState({
    token: null,
    userId: null,
    email: "",
  });
  const [loginState, setLoginState] = useState(LOGIN_STATES.INITIAL);

  useEffect(() => {
    const getUserDetails = async () => {
      const { data: { data: userDetailsFromBackend }} = await axios.get(BACKEND_URLS.GET_USER_DETAILS, {
        headers: {
          Authorization: `Bearer ${token}`
        },
      });
      setUserDetails({
        ...userDetailsFromBackend,
        token,
      });
      setLoginState(LOGIN_STATES.LOGGED_IN);
      sendLoginTokenMessageToExtension();
    };

    if(!token) {
      setLoginState(LOGIN_STATES.LOGGED_OUT);
      return;
    }

    getUserDetails();
  }, [token]);

  const login = useGoogleLogin({
    flow: "auth-code",
    scope: "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events",
    onSuccess: async (codeResponse) => {
      // TODO: Handle case where user doesn't grant access to the calendar
      try {
        const { data: { data: backendToken } } = await axios({
          method: "post",
          url: BACKEND_URLS.GOOGLE_LOGIN,
          data: {
            code: codeResponse.code,
          }
        });
        localStorage.setItem("meetngapp-session", backendToken);
        setToken(
          backendToken,
        );
        sendLoginTokenMessageToExtension();
      } catch (e) {
        console.log("Error fetching refresh token from backend. Try again");
        console.log(e);
      }
    },
    onError: errorResponse => {
      setLoginState(LOGIN_STATES.ERROR);
      console.log(errorResponse);
    }
  });

  const logout = async () => {
    localStorage.removeItem("meetngapp-session");
    setToken(null);
    sendLogoutMessageToExtension();
    setLoginState(LOGIN_STATES.LOGGED_OUT);
  };

  let value = { loginState, userDetails, login, logout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
  return useContext(AuthContext);
}

export const RequireAuth = ({ children }) => {
  let auth = useAuth();
  let location = useLocation();

  if (auth.loginState === LOGIN_STATES.LOGGED_OUT) {
    return <Navigate to={ROUTES.LOGIN} state={{ from: location }} replace />;
  }

  if(auth.loginState === LOGIN_STATES.ERROR) {
    return (
      <div>
        Error occurred trying to check user login.
        <button onClick={auth.getUserDetails}>
          Please try again
        </button>
      </div>
    );
  }

  if(auth.loginState === LOGIN_STATES.LOGGED_IN) {
    return children;
  }

  return (
    <div id="auth-loading">
      <img src={MeetngLogo} alt="Logo" id="auth-loading-logo" />
      <div className="flex">
        <div className="circle-loader" />Checking user login. Please wait
      </div>
    </div>
  );
}
