import React from "react";
import Sidebar from "../../components/sidebar";

const DashboardApps = () => {
  return (
    <div id="dashboard-layout">
      <Sidebar />
      <div id="dashboard-contents">
        <h2>Apps</h2>
        <p>Coming soon!</p>
      </div>
    </div>
  )
};

export default DashboardApps;
