import React from "react";
import {Navigate} from "react-router-dom";

import IconGoogle from "../assets/icon_google_login.png";
import LogoColor from "../assets/logo_color.png";
import { LOGIN_STATES, ROUTES } from "../utils/constants";
import {useAuth} from "../utils/auth";

const LoginScreen = () => {
  let auth = useAuth();

  if(auth.loginState === LOGIN_STATES.LOGGED_IN) {
    return (
      <Navigate to={ROUTES.MEETINGS} />
    )
  }

  return (
    <div id="login_wrapper">
      <div id="login_div">
        <div id="login_logo_wrapper">
          <img src={LogoColor} alt="Meetng Logo" id="login_logo" />
        </div>
        <div id="login_contents">
          <h4>Sign up / Login</h4>

          <button onClick={auth.login} id="login_btn">
            <img src={IconGoogle} id="login_btn_icon" alt="Google icon" />
            <span>
              Continue with Google
            </span>
          </button>

          <p id="login_terms">
            By signing up, you agree to our <a href="/terms">Terms and Conditions</a> and <a href="/privacy_policy">Privacy Policy</a>
          </p>

          <p id="login_captcha">
            This site is protected by reCAPTCHA and Google Privacy Policy and Terms of Service apply
          </p>

          {
            auth.loginState === LOGIN_STATES.ERROR && (
              <div>Login failed. Please try again</div>
            )
          }

        </div>

      </div>
    </div>
  )
}

export default LoginScreen;
