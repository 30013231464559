import React from "react";
import LandingHeader from "../landing/header";

import ExtSuccessIllustration from "../assets/illustration_ext_success.png";

const ExtensionSuccessScreen = () => {
  return (
    <>
      <LandingHeader />
      <div id="ext-success-wrapper">
        <h1>TimeMachine is now active on Chrome!</h1>
        <div id="ext-success-flex">
          <img id="ext-success-img" src={ExtSuccessIllustration} alt=""/>
          <div id="ext-success-content">
            <p>Click the icon in Gmail to schedule a meeting</p>
            <a className="btn primary" href="https://mail.google.com/">Open GMail</a>
          </div>
        </div>
      </div>
    </>
  )
}

export default ExtensionSuccessScreen;
