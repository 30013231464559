import React from "react";
import StaticPageContent from "../components/static-page-content";

const PrivacyPolicy = () => {
  return <StaticPageContent>
    <h1>Privacy Policy</h1>
    <h2>Purpose</h2>
    <p>
      Adya.Care (“Adya,” “we,” or “us”) is committed to protecting your privacy, we take data protection and privacy
      very seriously. This Privacy Policy (“Policy”) describes how Adya.Care collects, uses, shares and secures the
      personal information you provide when you visit the Websites and Mobile Apps owned and operated by Adya.Care
      (listed below), and when you use our Service(s). It also describes your choices regarding use, access and
      correction of your personal information. The use of information collected through our Service(s) shall be limited
      to the purpose of providing the service for which you have engaged Adya.Care.
      <br/><br/>
      Please read this Privacy Policy carefully, as it governs how you use Adya.Care or its affiliate products. If you
      do not agree to this Privacy Policy, please do not use Adya.Care.
    </p>

    <h2>Notice to End Users</h2>
    <p>
      In some cases an administrator may create an account in Adya.Care on the behalf of a “End-User” and may provide
      his/her information, including Personal Information. In such cases the administrator who may be your Employer or a
      Adya.Care Authorised partner is our “Customer.” We collect Information under the direction of our customers and
      have no direct relationship with the End-User whose personal data we process.
      <br/><br/>

      An End-user’s use of the Adya.Care platform may be subject to the Customer’s policies, if any.
      <br/><br/>

      Our Customer must have the authority to act on behalf of the End-User and to consent to the collection and use of
      their Personal Information as described in this Privacy Policy.
      <br/><br/>

      If you are an End-user using our platform, please direct your privacy inquiries to your administrator. Adya.Care
      is not responsible for the privacy or security practices of our customers, which may differ from those set forth
      in this privacy statement.
      <br/><br/>

      Adya.Care does not send personalized advertising or Marketing communication to end users.

    </p>
    <h2>International Transfer</h2>
    <p>
      Personal data collected by Adya.Care may be stored and processed in your region, and in any other country where
      Adya.Care or its affiliates, data sub-processors operate facilities. To facilitate Adya.Care global operations, we
      may transfer and access such information from around the world, including from other countries in which the
      Company has operations. This Privacy Statement shall apply even if Adya.Care transfers Data to other countries.
      <br/><br/>

      Currently, Adya.Care and its data sub processors have data centers in United States, Singapore.
      <br/><br/>

      The data we hold may also be processed by employees operating in countries listed above, Such staff may be engaged
      in, among other things, product development and customer and technical support. The data protection and other laws
      of these countries might not be as comprehensive as those in your country. By submitting your data and/or using
      Our Services, You consent to the transfer, storing, and processing of Your data in these countries.
    </p>

    <h2>Use of your Personal Information and Other Data</h2>
    <p>
      Adya.Care uses this Privacy Policy to govern the use of personal information you provide to us. We use your
      personal information solely to provide our services to you. We also use your Personal Identifiable Information
      (PII)
      together with other Personally Non-Identifiable Information (non - PII) to help us better understand our users and
      to
      improve the content and functionality of our site.
    </p>
    <h2>Personal Identifiable Information</h2>
    <p>
      We may collect the following personal information from you in the following situations
      <br/><br/>
      When you visit our website or mobile apps and voluntarily provide the following information via one of our contact
      forms, onboarding process, or as part of regular engagement on the App:
      Contact Information, such as name, email address, mailing address, phone number, IP, fitness data
      Billing Information, such as credit card number and billing address;
      Google user data
      We will collect personal identification information from Users only if they voluntarily submit such information to
      us. Users can always refuse to supply personal identification information, except that it may prevent them from
      engaging in certain Site related activities.

      Disclosure: Adya.Care's use and transfer of information received from Google APIs to any other app will adhere to
      Google API Services User Data Policy, including the Limited Use requirements.

      Note: We access your google data like fitness metrics and email only on your permission. We use it to improve your
      experience with the product in a positive way. Your data is stored and handled securely and is not shared with
      other
      parties. All payment transactions are processed through secure payment gateway providers. We do not store any card
      information (other than the last 4 digits of your card) in our servers.

    </p>
    <h2>
      Non-Personal Identifiable Data:
    </h2>
    <p>
      We capture some Non Identifiable data in our logs. The data in the logs includes
    </p>
    <ul>
      <li>Device type</li>
      <li>Browser type</li>
      <li>Language preference</li>
      <li>Time zone</li>
      <li>Screen size</li>
      <li>Referring / exit pages</li>
    </ul>
    <h2>Use</h2>
    <p>
      We may use the personal information collected from within the Websites or when you use the Service(s) to:
    </p>
    <ul>
      <li>Provide you with the Service(s).</li>
      <li>Send you communication from the Service(s)</li>
      <li>Send you requested product or service information.</li>
      <li>Respond to customer service requests.</li>
      <li>Send you promotional and marketing communications.</li>
      <li>Personalised Advertisement</li>
      <li>Facilitate your transactions with other users when you use our Service(s).</li>
      <li>Perform statistical analyses of user behaviour and characteristics at an aggregate level in order to measure
        interest in and use of the various areas of the site. When you provide us with personal information about your
        contacts we will only use this information for the specific reason for which it is provided.
      </li>
    </ul>

    <h2>Share</h2>
    <p>
      If your subscription was provided by your employer or a Adya.Care Authorised partner, we will share information,
      including personal information with them.
      <br/><br/>
      We may transfer personal information to our Group Companies and sub-contractors that help us provide our
      Service(s).
      Transfers to subsequent third parties are covered by the service agreements with our data-processors. We reserve
      the
      right to disclose your personal data as required by applicable law, in response to lawful requests by public
      authorities, including meeting national security or law enforcement requirements and when we believe that
      disclosure
      is necessary to protect our rights and/or to comply with a judicial proceeding, court order, or other legal
      process
      served on us.
      <br/><br/>
      In the event Adya.Care goes through a business transition, such as a merger or acquisition by another company, or
      sale of all or a portion of its assets, your user account and personal data will likely be among the assets
      transferred. You will be notified via email and via a prominent notice on our Apps of any such change in ownership
      or control. We may also disclose your personal information to any other third party only with your prior consent.
    </p>

    <h2>Data from Third Parties</h2>
    <p>
      We also obtain data from third parties. We protect data obtained from third parties according to the practices
      described in this statement, plus any additional restrictions imposed by the source of the data. These third-party
      sources vary over time, but have included:
    </p>
    <ul>
      <li>Data brokers from which we purchase demographic data to supplement the data we collect.</li>
      <li>Social networks when you grant permission to access your data on one or more networks.</li>
      <li>Existing or past learners who participate in our referral program</li>
      <li>Service providers that help us determine a location based on your IP address in order to customize certain
        products to your location.
      </li>
      <li>Partners with which we offer co-branded services or engage in joint marketing activities, and</li>
      <li>Publicly-available sources such as open government databases or other data in the public domain.</li>
    </ul>
    <p>

      We may receive Personal Information about you from other sources like postal mail, telephone or fax. We may add
      this
      information to the information we have already collected from you via the Site in order to improve the products
      and
      services we provide.

    </p>

    <h2>Correction, Removal or Export of Personal Information</h2>
    <p>
      Adya.Care acknowledges that you have the right to access, edit and delete your personal information at any time.
      Upon request, we will provide you with reasonable access to the personal information we collect about you. You
      will have the opportunity to correct, update, modify or delete this information as follows:
    </p>
    <ul>
      <li>If you have independently signed up with Adya.Care: You may access, update or export your Personal
        Information included in your online Adya.Care account at any time
        by logging into your account and going to your profile or by reaching out to us
      </li>
      <li>If you are using an enterprise-provided account (Adya.Care Authorised Partner or your Employer)
        Please review the section Notice to End Users
      </li>
    </ul>
    <p>

      <b>Exceptions in Deletion:</b> If you request access to your personal information and discover that that
      information is
      wrong, we strive to give you ways to update it quickly or to delete it – unless we have to keep that information
      for
      legitimate business or legal purposes. We aim to maintain our services in a manner that protects information from
      accidental or malicious destruction. Because of this, after you delete information from our services, we may not
      immediately delete residual copies from our active servers or backup systems

      <b>Exception in Data Portability:</b> There may be limits to the amount of information we can practically provide.
      For
      example, we may limit an individual’s access to personal information where the burden or expense of providing
      access
      would be disproportionate to the risks to the individual’s privacy or where doing so would violate others’ rights.

      <b>Exception in Data Correction:</b> Even after updates to your Personal Information, we may maintain and store
      older
      versions of such information within our databases, access logs and other records in order to comply with other
      routine data audits.

    </p>

    <h2>Content on Adya</h2>
    <p>
      <b>User Generated Content:</b> Adya platform does not tolerate objectionable content or abusive users. Accounts
      featuring
      objectionable content will be removed from Adya. Similarly, accounts that end up being used for hosting
      objectionable User Generated Content, or that develop a reputation among users of being abusive, will also be
      removed from Adya. Please write or report any objectionable and abusive content to us at shashwat@adya.care or
      admin@adya.care and we will help you with the resolution as needed. We act on objectionable content reports within
      24 hours by removing the content and ejecting the user who provided the offending content.
      <br/><br/>
      <b>Harassment & cyberbullying Guidelines:</b> Content that threatens individuals is not allowed on Adya. We also
      don't
      allow content that targets an individual with prolonged or malicious insults based on intrinsic attributes. If you
      find content or an account that violates this policy, report it.
      <br/><br/>
      <b>Hate Speech Guidelines:</b> Hate speech is not allowed on Adya. We remove content promoting violence or hatred
      against
      individuals or groups based on any of the following attributes: Age, Caste, Disability, Ethnicity, Gender Identity
      and Expression, Nationality, Race, Immigration Status, Religion, Sex/Gender, Sexual Orientation, Victims of a
      major
      violent event and their kin, Veteran Status. If you find content or an account that violates this policy, report
      it.
      <br/><br/>
      <b>Misinformation Guidelines:</b> Certain types of misleading or deceptive content with serious risk of egregious
      harm
      are
      not allowed on Adya. This includes certain types of misinformation that can cause real-world harm, like promoting
      harmful remedies or treatments, certain types of technically manipulated content, or content interfering with
      democratic processes. If you find content or an account that violates this policy, report it.
      <br/><br/>
      <b>Spam, deceptive practices, & scams:</b> Adya doesn’t allow spam, scams, or other deceptive practices that take
      advantage
      of the Adya group or community. We also don’t allow content where the main purpose is to trick others into leaving
      Adya for another site. If you find content or an account that violates this policy, report it.

    </p>

    <h2>Cookies and Other Tracking Technologies</h2>
    <p>
      Adya.Care and our third-party partners, such as our advertising and analytics partners, (listed here) use cookies
      and other tracking technologies (e.g., web beacons, device identifiers and pixels) to provide functionality and to
      recognize you across different Services and devices.

      The types of cookies used by Adya.Care includes:
    </p>
    <ul>
      <li>Essential Cookies: These cookies are essential for the basic functionalities offered by the Site and
        Service(s).
        These class of cookies helps in keeping a user logged in to the Service(s) and remember relevant information
        when
        they return to the Service(s).
      </li>
      <li>Insight Cookies: These are used for tracking the user activities within the Service(s), which in turn helps
        us in
        improving your user experience.
      </li>
      <li>Marketing Cookies: These are used for providing you with customized and interest-based ads based on your
        browsing
        behavior and other similar activities on our Websites.
      </li>
    </ul>
    <p>
      You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all
      cookies. You do this through your browser settings. Since each browser is different, look at your browser's Help
      Menu to learn the correct way to modify your cookies.
      <br/><br/>
      If you turn cookies off, some of the features that make your site experience more efficient may not function
      properly.
    </p>
    <h2>Advertisement</h2>
    <p>
      We partner with third parties to manage our advertising on other sites. Our third party partners may use
      technologies such as cookies to gather information about your activities on our Website and other sites in order
      to
      provide you advertising based upon your browsing activities and interests.
    </p>

    <h2>Analytics</h2>
    <p>Adya.Care uses third party software for analytics. All metrics information collected from your usage of the
      Service(s) shall be transmitted to our analytics provider. This information is then used to evaluate how users use
      our platform, and to compile statistical reports on user activity for us. We further use the same statistical
      analytics tool to track or to collect your Personally Identifiable Information (PII). We will use this information
      in order to maintain, enhance, or add to the functionality of the Service(s) and to personalize the experience for
      you. Aggregated information and analysis may be made public where desired by Adya.Care.</p>

    <h2>Links to Third Party Sites</h2>
    <p>Our Apps and Websites contain links to other websites that are not owned or controlled by Adya.Care. Please be
      aware
      that we are not responsible for the privacy practices of such other websites or third parties. We encourage you to
      be aware when you leave our apps and websites and to read the privacy policies of each and every website that
      collects personal information.</p>

    <h2>Communications from us</h2>
    <p>
      We may use your email address to send our newsletters and/or marketing communications. If you no longer wish to
      receive these communications, you can opt out by following the instructions contained in the emails you receive or
      by contacting us at admin@adya.care
      <br/><br/>
      We will send you announcements related to the Service(s) on occasions when it is necessary to do so. For instance,
      if our Services) is temporarily suspended for maintenance, we might send you an email. Generally, you may not
      opt-out of communications which are not promotional in nature. If you do not wish to receive them, you may delete
      your Account.
    </p>

    <h2>Information Security</h2>
    <p>Our Apps and Service(s) have industry standard security measures in place to protect against the loss, misuse,
      and
      alteration of the information under our control. When you provide us with sensitive information (such as credit
      card
      information or login credentials), we will encrypt that information via Secure Socket Layer (SSL).
      <br/><br/>
      Although we will do our best to protect your personal data, we cannot guarantee the security of your data
      transmitted to our Apps or via the Service(s) and any transmission is at your own risk. Once we receive your
      personal information, we will use strict procedures and security features to try to prevent unauthorised access.
      <br/><br/>
      We adopt appropriate data collection, storage and processing practices and security measures to protect against
      unauthorized access, alteration, disclosure or destruction of your personal information, username, password,
      transaction information and data stored on our Site.
    </p>
    <ul>
      <li>Our Websites and App are scanned on a regular basis for security holes and known vulnerabilities in order to
        make
        your visit to our site as safe as possible.
      </li>
      <li>We use regular Malware Scanning.</li>
      <li>Your personal information is contained behind secured networks and is only accessible by a limited number of
        persons
        who have special access rights to such systems, and are required to keep the information confidential. In
        addition,
        all sensitive/credit information you supply is encrypted via Secure Socket Layer (SSL) technology.
      </li>
      <li>All payment transactions are processed through secure gateway providers and are not stored or processed on
        our
        servers
      </li>
    </ul>

    <h2>Testimonials</h2>
    <p>We post customer testimonials/comments/reviews on our Websites which may contain personal information. Prior to
      posting the testimonial, we obtain the customer's consent to post their name along with their testimonial. If you
      want your testimonial removed, please reach out to us at admin@adya.care</p>

    <h2>Children's Personal Information</h2>
    <p>
      Adya.Care does not knowingly collect any personal information from children under the age of 16. If you are under
      the age of 16, please do not submit any personal information through our Apps or Service(s). We encourage parents
      and legal guardians to monitor their children’s Internet usage and to help enforce this Policy by instructing
      their
      children never to provide personal information through our Service(s) or Apps without their permission. If you
      have
      reason to believe that a child under the age of 16 has provided personal information to us through our Apps or
      Service(s), please contact us and we will endeavor to delete that information and terminate the child's account
      from
      our databases.
    </p>

    <h2>Amendments</h2>
    <p>Adya.Care has the discretion to update this privacy policy at any time. When we do, we will post a notification
      on
      the main page of our Site, revise the updated date at the bottom of this page and send you an email. We encourage
      Users to frequently check this page for any changes to stay informed about how we are helping to protect the
      personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy
      policy periodically and become aware of modifications. Your continued use of this Apps or the Service(s) following
      the posting of any amendment, modification, or change to this Policy shall constitute your acceptance of the
      amendments to this Policy. You can choose to discontinue use of the Apps or Service(s), if you do not accept the
      terms of this Policy, or any modified version of this Policy.</p>

    <h2>Contacting Adya.Care</h2>
    <p>If you have any questions about this privacy policy or your dealings with the Adya.Care, you can reach out to us
      at
      admin@adya.care</p>
  </StaticPageContent>
};

export default PrivacyPolicy;
