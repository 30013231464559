import React from "react";
import LogoColor from "../assets/logo_color.png";
import {LOGIN_STATES, ROUTES} from "../utils/constants";
import {useAuth} from "../utils/auth";

const LandingHeader = () => {
  const auth = useAuth();
  return (
    <div id="landing-header" className="landing-width">
      <img src={LogoColor} alt="Meeetng Logo" id="landing-logo" />
      <div id="">
        {
          (auth.loginState === LOGIN_STATES.LOGGED_IN) && (
            <a href={ROUTES.MEETINGS} id="header-dash-link">
              Go to Dashboard
            </a>
          )
        }
      </div>
    </div>
  );
};

export default LandingHeader;
