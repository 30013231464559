import React from "react";

export const INVITEE_STATES = {
  INEXISTENT: "INEXISTENT",
  UNCONFIRMED: "UNCONFIRMED",
  CONFIRMED: "CONFIRMED",
};

const StatusIcon = {
  [INVITEE_STATES.INEXISTENT]: <div className="meeting-det-invitee-icon inexistent">
    <span className="material-symbols-outlined">
question_mark
</span>
  </div>,
  [INVITEE_STATES.CONFIRMED]: <div className="meeting-det-invitee-icon confirmed">
    <span className="material-symbols-outlined">
      done
    </span>
  </div>,
  [INVITEE_STATES.UNCONFIRMED]: <div className="meeting-det-invitee-icon unconfirmed">
    <span className="material-symbols-outlined">
      hourglass
</span>
  </div>
}

const InviteeItem = ({viewerEmail, details}) => {
  return (
    <div className="meeting-det-invitees-item">
      {StatusIcon[details.status]}
      <div>
        {viewerEmail === details.email ? "me" : (details.name || details.email)}
        {/*{*/}
        {/*  details.name && (*/}
        {/*    <>*/}
        {/*      <br />*/}
        {/*      <span className="det-invitees-item-email">{details.email}</span>*/}
        {/*    </>*/}
        {/*  )*/}
        {/*}*/}
      </div>
    </div>
  );
};

export default InviteeItem;
